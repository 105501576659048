$transition-link: color .2s ease !default; 
$transition-input: color .2s ease !default; 

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 1rem !default; // Assumes the browser default, typically `13px`
$font-size-lg: $font-size-base * 1.075 !default;   // 14.04px
$font-size-sm: $font-size-base * .925 !default;   // 12.025px

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 500 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 700 !default;

$font-weight-base: $font-weight-normal !default;